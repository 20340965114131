<template>
    <page back>
        <el-form
            ref="addForm"
            size="small"
            :model="formModel"
            :rules="formRules"
        >
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="raffleCode"
                    label="抽奖码"
                >
                    <el-input
                        v-model="queryFormModel.raffleCode"
                        placeholder="请输入抽奖码"
                    />
                </el-form-item>
                <el-form-item
                    prop="rafflePrizeId"
                    label="奖品"
                >
                    <el-select
                        v-model="queryFormModel.rafflePrizeId"
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="item in formModel.rafflePrizeList"
                            :key="item.id"
                            :label="item.rafflePrizeLevel + ' ' + item.rafflePrizeName"
                            :value="item.id + ''"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item
                    prop="rafflePrizeType"
                    label="奖品类型"
                >
                    <dictionaries-picker
                        type="rafflePrizeType"
                        v-model="queryFormModel.rafflePrizeType"
                    />
                </el-form-item>
                <el-form-item
                    prop="raffleDetailStatus"
                    label="抽奖状态"
                >
                    <dictionaries-picker
                        type="raffleDetailStatus"
                        v-model="queryFormModel.raffleDetailStatus"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-download"
                        size="small"
                        @click="exportExcel"
                    >
                        导出
                    </el-button>
                </el-button-group>
            </div>
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column
                    prop="raffleCode"
                    label="抽奖码"
                    width="240"
                >
                    <template #default="scope">
                        <QrCode
                            :text="scope.row.raffleCode"
                            :url="scope.row.raffleCodeUrl"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    prop="raffleDetailStatusName"
                    label="抽奖状态"
                    width="100"
                />
                <el-table-column
                    label="抽奖时间"
                    width="135"
                >
                    <template slot-scope="scope">
                        {{ scope.row.raffleTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="rafflePrizeTypeName"
                    label="奖品类型"
                    min-width="100"
                />
                <el-table-column
                    prop="rafflePrizeName"
                    label="奖品名称"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.raffleDetailStatus === 10">
                            <el-select
                                v-model="scope.row.rafflePrizeId"
                                placeholder="请选择"
                                @change="changeRafflePrize(scope.row)"
                            >
                                <el-option
                                    v-for="item in formModel.rafflePrizeList"
                                    :key="item.id"
                                    :label="item.rafflePrizeLevel + ' ' + item.rafflePrizeName"
                                    :value="item.id"
                                />
                            </el-select>
                        </span>
                        <span v-else>
                            {{ scope.row.rafflePrizeLevel }} {{ scope.row.rafflePrizeName }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="抽奖信息"
                    min-width="100"
                    v-if="formModel.raffleStatus !== 10"
                >
                    <template
                        slot-scope="scope"
                        v-if="scope.row.raffleDetailStatus !== 10"
                    >
                        <div>帐号用户名：{{ scope.row.userName }}</div>
                        <div>帐号手机：{{ scope.row.mobile }}</div>
                        <div v-if="scope.row.rafflePrizeType === 3">
                            姓名：{{ scope.row.raffleConfig.fullName }}
                        </div>
                        <div v-if="scope.row.rafflePrizeType === 3">
                            联系电话：{{ scope.row.raffleConfig.mobile }}
                        </div>
                        <div v-if="scope.row.rafflePrizeType === 3">
                            地址：{{ scope.row.raffleConfig.address }}
                        </div>
                        <div v-if="scope.row.rafflePrizeType === 3 && scope.row.raffleConfig.idCardNo">
                            身份证号：{{ scope.row.raffleConfig.idCardNo }}
                        </div>
                        <div v-if="scope.row.rafflePrizeType === 3 && scope.row.raffleConfig.expressName">
                            物流公司名称：{{ scope.row.raffleConfig.expressName }}
                        </div>
                        <div v-if="scope.row.rafflePrizeType === 3 && scope.row.raffleConfig.trackingNumber">
                            物流单号：{{ scope.row.raffleConfig.trackingNumber }}
                        </div>
                        <div v-if="scope.row.rafflePrizeType === 3 && scope.row.raffleConfig.shippingTime">
                            发货时间：{{ scope.row.raffleConfig.shippingTime }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="60"
                    v-if="formModel.raffleStatus !== 10"
                >
                    <template #default="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="setComplete(scope.row)"
                            v-if="scope.row.raffleDetailStatus === 30 && scope.row.rafflePrizeType === 3"
                        >
                            核销
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </el-form>

        <el-dialog
            title="核销"
            center
            width="600px"
            :visible.sync="addDialog.isVisible"
            @closed="onAddDialogCancel"
        >
            <el-form
                ref="addForm"
                size="small"
                :model="addDialog.formModel"
                :rules="addDialog.formRules"
            >
                <el-form-item
                    prop="expressName"
                    label="物流公司名称"
                    label-width="8em"
                >
                    <el-input
                        v-model="addDialog.formModel.expressName"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="trackingNumber"
                    label="物流单号"
                    label-width="8em"
                >
                    <el-input
                        v-model="addDialog.formModel.trackingNumber"
                        auto-complete="off"
                    />
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onAddDialogConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onAddDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixin';

export default {
    name: 'RaffleEdit',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            formModel: {
                id: '',
                raffleName: '',
                raffleCover: [],
                raffleAdImage: [],
                addressKeywords: '',
                raffleTime: [],
                raffleDesc: [],
                raffleCodeNum: '1',
                raffleStatus: '',
                remarks: '',
                rafflePrizeList: [],
            },
            formRules: {
                raffleName: [
                    {
                        required: true,
                        message: '请输入活动名称',
                        trigger: 'blur',
                    },
                ],
                raffleCover: [
                    {
                        required: true,
                        message: '请上传活动封面',
                        trigger: 'blur',
                    },
                ],
                raffleTime: [
                    {
                        required: true,
                        message: '请选择活动时间',
                    },
                ],
                raffleCodeNum: [
                    {
                        required: true,
                        message: '请选择活动码数量',
                    },
                ],
            },

            selectDialog: {
                // 对话框显示
                isVisible: false,
                // 查询数据
                queryFormModel: {
                    // 优惠券名称
                    couponDesc: '',
                },
                // 表单数据
                tableData: [],
                // 分页
                pagination: {},
                // 当前选择行
                currentTableSelect: [],
                row: {},
            },
            queryFormModel: {
                raffleId: '',
                rafflePrizeId: '',
                rafflePrizeType: '',
                raffleCode: '',
                raffleDetailStatus: '',
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
            addDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    expressName: '',
                    trackingNumber: '',
                },
                // 表单校验规则
                formRules: {
                    expressName: {
                        required: true,
                        message: '请输入物流公司名称',
                        trigger: 'blur',
                    },
                    trackingNumber: {
                        required: true,
                        message: '请输入物流单号',
                        trigger: 'blur',
                    },
                },
            },
        };
    },
    methods: {
        onConfirm(isSubmit) {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$api.Pm.Raffle.save({
                    ...this.formModel,
                    isSubmit,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.$router.back();
                });
            });
        },
        initDetail() {
            this.$api.Pm.Raffle.getDetail({ id: this.formModel.id }).then(json => {
                const res = json.data.data;
                this.$utils.Common.formModelMerge(this.formModel, res);
            });
        },
        // region rafflePrize
        addRafflePrize() {
            this.formModel.rafflePrizeList.push({
                rafflePrizeType: '',
                rafflePrizeName: undefined,
                rafflePrizeConfig: undefined,
                rafflePrizeImage: [],
                raffleDrawImage: [],
                raffleNoticeImage: [],
                rafflePrizeProbability: undefined,
                rafflePrizeNumber: undefined,
                isAvailable: true,
                selectDataList: [],
            });
        },
        deleteRafflePrize(row) {
            this.formModel.rafflePrizeList.splice(this.formModel.rafflePrizeList.indexOf(row), 1);
        },
        changeRafflePrizeType(row) {
            if (+row.rafflePrizeType === 1) {
                row.rafflePrizeConfig = {
                    money: undefined,
                };
            } else if (+row.rafflePrizeType === 2) {
                row.rafflePrizeConfig = {
                    selectDataList: [],
                };
            } else if (+row.rafflePrizeType === 3) {
                row.rafflePrizeConfig = {
                    isNeedIdCard: false,
                };
            } else {
                row.rafflePrizeConfig = '';
            }
        },
        // endregion
        /*选择优惠券*/
        onAddSelect(row) {
            this.selectDialog.row = row;
            this.selectDialog.isVisible = true;
            this.$nextTick(() => {
                this.onSelectDialogQuery();
                // 初始化已选择数据
                if (this.$refs.selectDialogTable) {
                    this.$refs.selectDialogTable.clearSelection();
                }
                this.selectDialog.row.selectDataList.forEach(item => {
                    this.$refs.selectDialogTable.toggleRowSelection(item, true);
                });
            });
        },
        onSelectDialogQuery(pagination) {
            return this.$api.Pm.CouponGive.data({
                ...this.selectDialog.queryFormModel,
                ...pagination,
                giveScope: 4,
                couponGiveMode: 1,
            }).then(json => {
                const res = json.data;
                this.selectDialog.tableData = res.data;
                this.selectDialog.pagination = res.pagination;
            });
        },
        onSelectDialogSelectionChange(val) {
            this.selectDialog.currentTableSelect = val;
        },
        onResetSelectDialog() {
            this.$refs.selectDialogQueryForm.resetFields();
        },
        onSelectConfirm() {
            this.selectDialog.row.selectDataList = [...this.selectDialog.currentTableSelect];
            this.selectDialog.isVisible = false;
        },
        onSelectCancel() {
            this.selectDialog.isVisible = false;
        },
        onDeleteSelect(list, row) {
            list.splice(list.indexOf(row), 1);
        },
        getListData(queryData) {
            return this.$api.Pm.RaffleDetail.data({
                ...queryData,
                raffleId: this.formModel.id,
            }).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        changeRafflePrize(row) {
            this.$api.Pm.RaffleDetail.changeRafflePrize({
                id: row.id,
                rafflePrizeId: row.rafflePrizeId,
            }).then(json => {
                const res = json.data;
                if (res.success) {
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                } else {
                    this.$alert(res.msg, '温馨提示', {
                        type: 'error',
                        // callback(action) {},
                    });
                    row.rafflePrizeId = null;
                }
            });
        },
        setComplete(row) {
            this.addDialog.formModel.id = row.id;
            this.addDialog.isVisible = true;
        },
        onAddDialogConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$confirm('是否确定发货并更改领奖状态！', '温馨提示', {
                    type: 'warning',
                }).then(() => {
                    this.$api.Pm.RaffleDetail.setComplete({
                        ...this.addDialog.formModel,
                    }).then(json => {
                        const res = json.data;
                        this.$message({
                            message: res.msg,
                            type: 'success',
                        });
                        this.addDialog.isVisible = false;
                        this.onQuery(this.pagination);
                    });
                });
            });
        },
        onAddDialogCancel() {
            const { addForm } = this.$refs;
            addForm.resetFields();
            const { formModel } = this.addDialog;
            formModel.id = '';
            this.addDialog.isVisible = false;
        },
        //excel导出
        exportExcel() {
            this.$http.href('/pm/raffleDetail/exportExcel', {
                ...this.queryFormModel,
                raffleId: this.formModel.id,
            });
        },
    },
    created() {
        this.formModel.id = this.$route.params.id;
        if (this.formModel.id) {
            this.initDetail();
        }
    },
};
</script>

<style lang="scss">
</style>
