<template>
    <page back>
        <el-form
            ref="addForm"
            size="small"
            :model="formModel"
            :rules="formRules"
        >
            <el-card
                size="small"
                shadow="never"
            >
                <div
                    slot="header"
                    class="clearfix"
                >
                    <span>基本信息</span>
                </div>
                <el-row
                    :gutter="10"
                    style="overflow: hidden"
                >
                    <el-col :span="18">
                        <el-form-item
                            prop="raffleName"
                            label="活动名称"
                            label-width="9em"
                        >
                            <el-input
                                v-model="formModel.raffleName"
                                auto-complete="off"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="raffleCover"
                            label="活动封面"
                            label-width="9em"
                        >
                            <upload
                                validate-md5
                                action="/rs/attachment/uploadRaffleImage"
                                :limit="1"
                                v-model="formModel.raffleCover"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="raffleAdImage"
                            label="活动广告图"
                            label-width="9em"
                        >
                            <upload
                                draggable
                                validate-md5
                                action="/rs/attachment/uploadRaffleImage"
                                :limit="5"
                                v-model="formModel.raffleAdImage"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="raffleTemplateImage"
                            label="活动模板图"
                            label-width="9em"
                        >
                            <upload
                                draggable
                                validate-md5
                                action="/rs/attachment/uploadRaffleImage"
                                v-model="formModel.raffleTemplateImage"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="raffleDesc"
                            label="活动描述"
                            label-width="9em"
                        >
                            <upload
                                validate-md5
                                action="/rs/attachment/uploadRaffleImage"
                                :limit="5"
                                v-model="formModel.raffleDesc"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="raffleTime"
                            label="活动时间"
                            label-width="9em"
                        >
                            <el-date-picker
                                v-model="formModel.raffleTime"
                                type="datetimerange"
                                format="yyyy-MM-dd HH:mm:ss"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :disabled="!(!formModel.id || formModel.raffleStatus === 10)"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="raffleCodeNum"
                            label="活动码数量"
                            label-width="9em"
                        >
                            <el-input-number
                                controls-position="right"
                                :min="0"
                                :precision="0"
                                v-model.number="formModel.raffleCodeNum"
                                :disabled="!!formModel.id"
                            />
                        </el-form-item>  
                        <el-form-item
                            prop="remarks"
                            label="备注"
                            label-width="9em"
                        >
                            <el-input
                                type="textarea"
                                v-model="formModel.remarks"
                                auto-complete="off"
                                rows="4"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
            <el-card
                size="small"
                shadow="never"
                class="ma-t"
            >
                <div
                    slot="header"
                    class="clearfix"
                >
                    <span>奖品信息</span>
                    <el-button
                        class="ma-l"
                        type="success"
                        size="small"
                        @click="addRafflePrize"
                        v-if="!formModel.id || formModel.raffleStatus === 10"
                    >
                        新 增
                    </el-button>
                </div>
                <el-table
                    stripe
                    border
                    size="small"
                    :data="formModel.rafflePrizeList"
                    style="width: 100%"
                >
                    <el-table-column
                        prop="rafflePrizeType"
                        label="奖品类型"
                        width="120"
                    >
                        <template #default="scope">
                            <dictionaries-picker
                                size="small"
                                type="rafflePrizeType"
                                v-model="scope.row.rafflePrizeType"
                                @change="changeRafflePrizeType(scope.row)"
                                :disabled="!(!formModel.id || formModel.raffleStatus === 10)"
                            />
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="rafflePrizeLevel"
                        label="奖品等级名称"
                        width="130"
                    >
                        <template #default="scope">
                            <el-input
                                size="small"
                                v-model="scope.row.rafflePrizeLevel"
                                auto-complete="off"
                                placeholder="请输入奖品等级名称"
                            />
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="rafflePrizeName"
                        label="奖品名称"
                        width="180"
                    >
                        <template #default="scope">
                            <el-input
                                size="small"
                                v-model="scope.row.rafflePrizeName"
                                auto-complete="off"
                                placeholder="请输入奖品名称"
                            />
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="rafflePrizeConfig"
                        label="奖品配置"
                        min-width="200"
                    >
                        <template #default="scope">
                            <div v-if="+scope.row.rafflePrizeType === 1">
                                <el-input-number
                                    size="small"
                                    v-model="scope.row.money"
                                    controls-position="right"
                                    :min="0"
                                    :precision="2"
                                    :disabled="!(!formModel.id || formModel.raffleStatus === 10)"
                                />元
                            </div>
                            <div v-if="+scope.row.rafflePrizeType === 2">
                                <div
                                    class="ma-b"
                                    v-if="(!formModel.id || formModel.raffleStatus === 10)"
                                >
                                    <el-button-group>
                                        <el-button
                                            type="success"
                                            icon="el-icon-plus"
                                            size="small"
                                            @click="onAddSelect(scope.row)"
                                        >
                                            新增
                                        </el-button>
                                    </el-button-group>
                                </div>
                                <!-- 表格 -->
                                <el-table
                                    stripe
                                    border
                                    size="small"
                                    :data="scope.row.selectDataList"
                                    style="width: 100%"
                                >
                                    <el-table-column
                                        prop="couponDesc"
                                        label="优惠券名称"
                                        min-width="100"
                                    />
                                    <el-table-column
                                        prop="couponUseScopeTypeName"
                                        label="优惠券使用范围"
                                        min-width="100"
                                    />
                                    <el-table-column
                                        prop="couponAmountTypeName"
                                        label="优惠券金额类型"
                                        min-width="100"
                                    />
                                    <el-table-column
                                        prop="couponAmount"
                                        label="优惠券金额或比例"
                                        min-width="100"
                                    />
                                    <el-table-column
                                        prop="couponUseAmount"
                                        label="优惠券满减限制"
                                        min-width="100"
                                    />
                                    <el-table-column
                                        prop="remarks"
                                        label="发放备注"
                                        min-width="100"
                                    />
                                    <el-table-column
                                        fixed="right"
                                        label="操作"
                                        width="45"
                                        v-if="(!formModel.id || formModel.raffleStatus === 10)"
                                    >
                                        <template slot-scope="scope1">
                                            <el-button
                                                type="text"
                                                size="small"
                                                @click="onDeleteSelect(scope.row.selectDataList,scope1.row)"
                                            >
                                                删除
                                            </el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                            <div v-if="+scope.row.rafflePrizeType === 3">
                                是否需要身份证:<el-switch
                                    v-model="scope.row.isNeedIdCard"
                                    :disabled="!(!formModel.id || formModel.raffleStatus === 10)"
                                />
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="rafflePrizeImage"
                        label="奖品转盘图片"
                        width="120"
                    >
                        <template #default="scope">
                            <upload
                                validate-md5
                                action="/rs/attachment/uploadRaffleImage"
                                :limit="1"
                                v-model="scope.row.rafflePrizeImage"
                                tips=""
                            />
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="raffleDrawImage"
                        label="奖品中奖图片"
                        width="120"
                    >
                        <template #default="scope">
                            <upload
                                validate-md5
                                action="/rs/attachment/uploadRaffleImage"
                                :limit="1"
                                v-model="scope.row.raffleDrawImage"
                                tips=""
                            />
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="raffleNoticeImage"
                        label="领奖提示"
                        width="120"
                    >
                        <template #default="scope">
                            <upload
                                validate-md5
                                action="/rs/attachment/uploadRaffleImage"
                                :limit="1"
                                v-model="scope.row.raffleNoticeImage"
                                tips=""
                            />
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="rafflePrizeProbability"
                        label="奖品概率"
                        width="80"
                    >
                        <template #default="scope">
                            <!--                            <el-input-number
                                size="small"
                                v-model="scope.row.rafflePrizeProbability"
                                controls-position="right"
                                :min="0"
                                :precision="4"
                                :disabled="!(!formModel.id || formModel.raffleStatus === 10)"
                            />-->
                            {{ (scope.row.rafflePrizeNumber/ formModel.raffleCodeNum * 100).toFixed(4) }}%
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="rafflePrizeNumber"
                        label="奖品数量"
                        width="155"
                        v-if="!formModel.id || formModel.raffleStatus === 10"
                    >
                        <template #default="scope">
                            <el-input-number
                                size="mini"
                                v-model="scope.row.rafflePrizeNumber"
                                controls-position="right"
                                :min="0"
                                :disabled="!(!formModel.id || formModel.raffleStatus === 10)"
                            />
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="rafflePrizeNumber"
                        label="奖品数量"
                        width="80"
                        v-if="formModel.raffleStatus !== 10"
                    >
                        <template #default="scope">
                            {{ scope.row.rafflePrizeNumber }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="sendOutNumber"
                        label="已中奖数量"
                        width="90"
                        v-if="formModel.raffleStatus !== 10"
                    >
                        <template #default="scope">
                            {{ scope.row.sendOutNumber }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="isAvailable"
                        label="是否可用"
                        width="70"
                    >
                        <template #default="scope">
                            <el-switch
                                class="ma-l"
                                size="small"
                                v-model="scope.row.isAvailable"
                            />
                        </template>
                    </el-table-column>
                    <el-table-column
                        fixed="right"
                        label="操作"
                        width="60"
                        v-if="!formModel.id || formModel.raffleStatus === 10"
                    >
                        <template #default="scope">
                            <el-button
                                type="text"
                                size="small"
                                @click="deleteRafflePrize(scope.row)"
                            >
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-card>
            <!--            <el-card
                size="small"
                shadow="never"
                class="ma-t"
                v-if="formModel.id"
            >
                <div
                    slot="header"
                    class="clearfix"
                >
                    <span>抽奖码信息</span>
                </div>
                <el-form
                    ref="queryForm"
                    class="second-header npa-b"
                    size="small"
                    :inline="true"
                    :model="queryFormModel"
                >
                    <el-form-item
                        prop="raffleCode"
                        label="抽奖码"
                    >
                        <el-input
                            v-model="queryFormModel.raffleCode"
                            placeholder="请输入抽奖码"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="rafflePrizeId"
                        label="奖品"
                    >
                        <el-select
                            v-model="queryFormModel.rafflePrizeId"
                            placeholder="请选择"
                        >
                            <el-option
                                v-for="item in formModel.rafflePrizeList"
                                :key="item.id"
                                :label="item.rafflePrizeLevel + ' ' + item.rafflePrizeName"
                                :value="item.id + ''"
                            />
                        </el-select>
                    </el-form-item>
                    <el-form-item
                        prop="rafflePrizeType"
                        label="奖品类型"
                    >
                        <dictionaries-picker
                            type="rafflePrizeType"
                            v-model="queryFormModel.rafflePrizeType"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="raffleDetailStatus"
                        label="抽奖状态"
                    >
                        <dictionaries-picker
                            type="raffleDetailStatus"
                            v-model="queryFormModel.raffleDetailStatus"
                        />
                    </el-form-item>
                    <el-form-item>
                        <el-button
                            type="primary"
                            @click="onQuery()"
                        >
                            查询
                        </el-button>
                        <el-button
                            type="success"
                            @click="onReset"
                        >
                            重置
                        </el-button>
                    </el-form-item>
                </el-form>
                <div
                    class="ma-b"
                >
                    <el-button-group>
                        <el-button
                            type="success"
                            icon="el-icon-download"
                            size="small"
                            @click="exportExcel"
                        >
                            导出
                        </el-button>
                    </el-button-group>
                </div>
                <el-table
                    stripe
                    border
                    size="small"
                    :data="tableData"
                    style="width: 100%"
                >
                    <el-table-column
                        prop="raffleCode"
                        label="抽奖码"
                        width="240"
                    />
                    <el-table-column
                        prop="raffleDetailStatusName"
                        label="抽奖状态"
                        width="100"
                    />
                    <el-table-column
                        label="抽奖时间"
                        width="135"
                    >
                        <template slot-scope="scope">
                            {{ scope.row.raffleTime | moment('YYYY-MM-DD HH:mm:ss') }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="rafflePrizeTypeName"
                        label="奖品类型"
                        min-width="100"
                    />
                    <el-table-column
                        prop="rafflePrizeName"
                        label="奖品名称"
                        min-width="100"
                    >
                        <template slot-scope="scope">
                            <span v-if="formModel.raffleStatus === 10">
                                <el-select
                                    v-model="scope.row.rafflePrizeId"
                                    placeholder="请选择"
                                    @change="changeRafflePrize(scope.row)"
                                >
                                    <el-option
                                        v-for="item in formModel.rafflePrizeList"
                                        :key="item.id"
                                        :label="item.rafflePrizeLevel + ' ' + item.rafflePrizeName"
                                        :value="item.id"
                                    />
                                </el-select>
                            </span>
                            <span v-if="formModel.raffleStatus !== 10">
                                {{ scope.row.rafflePrizeLevel }} {{ scope.row.rafflePrizeName }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="抽奖信息"
                        min-width="100"
                        v-if="formModel.raffleStatus !== 10"
                    >
                        <template
                            slot-scope="scope"
                            v-if="scope.row.raffleDetailStatus !== 10"
                        >
                            <div>帐号用户名：{{ scope.row.userName }}</div>
                            <div>帐号手机：{{ scope.row.mobile }}</div>
                            <div v-if="scope.row.rafflePrizeType === 3">
                                姓名：{{ scope.row.raffleConfig.fullName }}
                            </div>
                            <div v-if="scope.row.rafflePrizeType === 3">
                                联系电话：{{ scope.row.raffleConfig.mobile }}
                            </div>
                            <div v-if="scope.row.rafflePrizeType === 3">
                                地址：{{ scope.row.raffleConfig.address }}
                            </div>
                            <div v-if="scope.row.rafflePrizeType === 3 && scope.row.raffleConfig.idCardNo">
                                身份证号：{{ scope.row.raffleConfig.idCardNo }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        fixed="right"
                        label="操作"
                        width="60"
                        v-if="formModel.raffleStatus !== 10"
                    >
                        <template #default="scope">
                            <el-button
                                type="text"
                                size="small"
                                @click="setComplete(scope.row)"
                                v-if="scope.row.raffleDetailStatus === 30 && scope.row.rafflePrizeType === 3"
                            >
                                已领奖
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                &lt;!&ndash; 分页 &ndash;&gt;
                <div class="ma-t ta-r">
                    <pagination
                        v-model="pagination"
                        @input="onQuery"
                    />
                </div>
            </el-card>-->

            <el-form-item
                label-width="9em"
                class="ma-t"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onConfirm(false)"
                    v-if="!formModel.id || formModel.raffleStatus === 10"
                >
                    保 存
                </el-button>
                <el-button
                    type="primary"
                    size="small"
                    @click="onConfirm(true)"
                >
                    保存并提交
                </el-button>
                <el-button
                    size="small"
                    @click="$router.back()"
                >
                    返 回
                </el-button>
            </el-form-item>
        </el-form>

        <el-dialog
            title="选择优惠券"
            center
            width="1200px"
            :visible.sync="selectDialog.isVisible"
            @closed="onSelectCancel"
        >
            <!-- 表单区 -->
            <el-form
                ref="selectDialogQueryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="selectDialog.queryFormModel"
            >
                <el-form-item
                    prop="couponDesc"
                    label="优惠券名称"
                >
                    <el-input
                        v-model="selectDialog.queryFormModel.couponDesc"
                        placeholder="请输入优惠券名称"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onSelectDialogQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onResetSelectDialog"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>

            <!-- 内容 -->
            <div class="body">
                <!-- 表格 -->
                <el-table
                    stripe
                    border
                    size="small"
                    :data="selectDialog.tableData"
                    style="width: 100%"
                    height="400"
                    row-key="id"
                    ref="selectDialogTable"
                    @selection-change="onSelectDialogSelectionChange"
                >
                    <el-table-column
                        type="selection"
                        :reserve-selection="true"
                        width="36"
                    />
                    <el-table-column
                        prop="couponDesc"
                        label="优惠券名称"
                        min-width="100"
                    />
                    <el-table-column
                        prop="couponUseScopeTypeName"
                        label="优惠券使用范围"
                        min-width="100"
                    />
                    <el-table-column
                        prop="couponAmountTypeName"
                        label="优惠券金额类型"
                        min-width="100"
                    />
                    <el-table-column
                        prop="couponAmount"
                        label="优惠券金额或比例"
                        min-width="100"
                    />
                    <el-table-column
                        prop="couponUseAmount"
                        label="优惠券满减限制"
                        min-width="100"
                    />
                    <el-table-column
                        prop="remarks"
                        label="发放备注"
                        min-width="100"
                    />
                </el-table>

                <!-- 分页 -->
                <div class="ma-t ta-r">
                    <pagination
                        v-model="selectDialog.pagination"
                        @input="onSelectDialogQuery"
                    />
                </div>
            </div>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onSelectConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onSelectCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixin';

export default {
    name: 'RaffleEdit',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            formModel: {
                id: '',
                raffleName: '',
                raffleCover: [],
                raffleAdImage: [],
                raffleTemplateImage: [],
                addressKeywords: '',
                raffleTime: [],
                raffleDesc: [],
                raffleCodeNum: '1',
                raffleStatus: '',
                remarks: '',
                rafflePrizeList: [],
            },
            formRules: {
                raffleName: [
                    {
                        required: true,
                        message: '请输入活动名称',
                        trigger: 'blur',
                    },
                ],
                raffleCover: [
                    {
                        required: true,
                        message: '请上传活动封面',
                        trigger: 'blur',
                    },
                ],
                raffleTime: [
                    {
                        required: true,
                        message: '请选择活动时间',
                    },
                ],
                raffleCodeNum: [
                    {
                        required: true,
                        message: '请选择活动码数量',
                    },
                ],
            },

            selectDialog: {
                // 对话框显示
                isVisible: false,
                // 查询数据
                queryFormModel: {
                    // 优惠券名称
                    couponDesc: '',
                },
                // 表单数据
                tableData: [],
                // 分页
                pagination: {},
                // 当前选择行
                currentTableSelect: [],
                row: {},
            },
            queryFormModel: {
                raffleId: '',
                rafflePrizeId: '',
                rafflePrizeType: '',
                raffleCode: '',
                raffleDetailStatus: '',
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
        };
    },
    methods: {
        onConfirm(isSubmit) {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$api.Pm.Raffle.save({
                    ...this.formModel,
                    isSubmit,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.$router.back();
                });
            });
        },
        initDetail() {
            this.$api.Pm.Raffle.getDetail({ id: this.formModel.id }).then(json => {
                const res = json.data.data;
                this.$utils.Common.formModelMerge(this.formModel, res);
            });
        },
        // region rafflePrize
        addRafflePrize() {
            this.formModel.rafflePrizeList.push({
                rafflePrizeType: '',
                rafflePrizeName: undefined,
                rafflePrizeConfig: undefined,
                rafflePrizeImage: [],
                raffleDrawImage: [],
                raffleNoticeImage: [],
                rafflePrizeProbability: undefined,
                rafflePrizeNumber: undefined,
                isAvailable: true,
                selectDataList: [],
            });
        },
        deleteRafflePrize(row) {
            this.formModel.rafflePrizeList.splice(this.formModel.rafflePrizeList.indexOf(row), 1);
        },
        changeRafflePrizeType(row) {
            if (+row.rafflePrizeType === 1) {
                row.rafflePrizeConfig = {
                    money: undefined,
                };
            } else if (+row.rafflePrizeType === 2) {
                row.rafflePrizeConfig = {
                    selectDataList: [],
                };
            } else if (+row.rafflePrizeType === 3) {
                row.rafflePrizeConfig = {
                    isNeedIdCard: false,
                };
            } else {
                row.rafflePrizeConfig = '';
            }
        },
        // endregion
        /*选择优惠券*/
        onAddSelect(row) {
            this.selectDialog.row = row;
            this.selectDialog.isVisible = true;
            this.$nextTick(() => {
                this.onSelectDialogQuery();
                // 初始化已选择数据
                if (this.$refs.selectDialogTable) {
                    this.$refs.selectDialogTable.clearSelection();
                }
                this.selectDialog.row.selectDataList.forEach(item => {
                    this.$refs.selectDialogTable.toggleRowSelection(item, true);
                });
            });
        },
        onSelectDialogQuery(pagination) {
            return this.$api.Pm.CouponGive.data({
                ...this.selectDialog.queryFormModel,
                ...pagination,
                giveScope: 4,
                couponGiveMode: 1,
            }).then(json => {
                const res = json.data;
                this.selectDialog.tableData = res.data;
                this.selectDialog.pagination = res.pagination;
            });
        },
        onSelectDialogSelectionChange(val) {
            this.selectDialog.currentTableSelect = val;
        },
        onResetSelectDialog() {
            this.$refs.selectDialogQueryForm.resetFields();
        },
        onSelectConfirm() {
            this.selectDialog.row.selectDataList = [...this.selectDialog.currentTableSelect];
            this.selectDialog.isVisible = false;
        },
        onSelectCancel() {
            this.selectDialog.isVisible = false;
        },
        onDeleteSelect(list, row) {
            list.splice(list.indexOf(row), 1);
        },
        getListData(queryData) {
            return this.$api.Pm.RaffleDetail.data({
                ...queryData,
                raffleId: this.formModel.id,
            }).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        changeRafflePrize(row) {
            this.$api.Pm.RaffleDetail.changeRafflePrize({
                id: row.id,
                rafflePrizeId: row.rafflePrizeId,
            }).then(json => {
                const res = json.data;
                if (res.success) {
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                } else {
                    this.$alert(res.msg, '温馨提示', {
                        type: 'error',
                        // callback(action) {},
                    });
                    row.rafflePrizeId = null;
                }
            });
        },
        setComplete(row) {
            this.$confirm('是否确定要更改领奖状态！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Pm.RaffleDetail.setComplete({
                    id: row.id,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
        //excel导出
        exportExcel() {
            this.$http.href('/pm/raffleDetail/exportExcel', {
                ...this.queryFormModel,
            });
        },
    },
    created() {
        this.formModel.id = this.$route.params.id;
        if (this.formModel.id) {
            this.initDetail();
        }
    },
};
</script>

<style lang="scss">
</style>
