<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-tabs
                class="classify-tabs"
                v-model="queryFormModel.raffleStatus"
                type="card"
                @tab-click="onQuery()"
            >
                <el-tab-pane
                    label="全部"
                    name="0"
                />
                <el-tab-pane
                    label="待提交"
                    name="10"
                />
                <el-tab-pane
                    label="进行中"
                    name="100"
                />
                <el-tab-pane
                    label="已结束"
                    name="99"
                />
            </el-tabs>
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                @keyup.enter.native="onQuery"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="raffleName"
                    label="活动名称"
                >
                    <el-input
                        v-model="queryFormModel.raffleName"
                        placeholder="请输入活动名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="raffleTime"
                    label="活动时间"
                >
                    <el-date-picker
                        v-model="queryFormModel.raffleTime"
                        type="datetimerange"
                        format="yyyy-MM-dd HH:mm:ss"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="$router.push('/pm/raffle/raffle/edit')"
                    >
                        新增
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
                @selection-change="onTableSelectionChange"
            >
                <el-table-column
                    prop="shopName"
                    label="商户"
                    min-width="100"
                />
                <el-table-column
                    prop="raffleName"
                    label="活动名称"
                    min-width="100"
                />
                <el-table-column
                    prop="raffleCover"
                    label="活动封面"
                    min-width="100"
                >
                    <template #default="scope">
                        <image-list :data="scope.row.raffleCoverUrl" />
                    </template>
                </el-table-column>
                <el-table-column
                    prop="raffleTime"
                    label="活动时间"
                    min-width="100"
                >
                    <template #default="scope">
                        <div>
                            开始时间：{{ scope.row.raffleBeginTime | moment('YYYY-MM-DD HH:mm:ss') }}
                        </div>
                        <div>
                            结束时间：{{ scope.row.raffleEndTime | moment('YYYY-MM-DD HH:mm:ss') }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="raffleStatusName"
                    label="活动状态"
                    min-width="100"
                />
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="140"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/pm/raffle/raffle/edit/' + scope.row.id)"
                            v-if="scope.row.raffleStatus !== 99"
                        >
                            编辑
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/pm/raffle/raffle/code/' + scope.row.id)"
                            v-if="scope.row.raffleStatus !== 99"
                        >
                            抽奖码
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="finishRaffle(scope.row.id)"
                            v-if="scope.row.raffleStatus === 100"
                        >
                            终止
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableDelete(scope.row.id)"
                            v-if="scope.row.raffleStatus === 10"
                        >
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixin';

export default {
    name: 'Object',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                raffleName: '',
                raffleTime: [],
                raffleStatus: '0',
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
        };
    },
    methods: {
        getListData(queryData) {
            return this.$api.Pm.Raffle.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        onTableDelete(ids) {
            this.$confirm('是否确定要删除！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Pm.Raffle.delete({ id: ids }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
        finishRaffle(id) {
            this.$confirm('是否确定要终止！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Pm.Raffle.finishRaffle({ id }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
    },
};
</script>

<style lang="scss">
</style>
